const MainPage = () => (
    <div className="">

        <div className="App text-center justify-center pt-10 bg-fit bg-cover bg-fixed text-white h-screen bg-black/[0.2]  bg-center w-[100%]" style={{ backgroundImage: `url('black.jpg' )` }} >

                <div className=" bg-gradient-to-b from-black to-transparent">
                    <div data-aos="fade-down" data-aos-duration="2000" className=" text-center lg:pt-6 md:pt-10 pt-14 md:text-2xl text-xl">Welcome to</div>
                    <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" className="title lg:text-9xl md:text-8xl -mt-5  sm:text-7xl  text-[56px] font-semibold tracking-widest text-[#F8C629]">HERCULIFT</div>
                </div>


                <div className="flex w-[100%] justify-center items-center absolute  md:bottom-[5%] -bottom-[3%] left-1/2  transform -translate-x-1/2 -translate-y-1/3  ">
                    <div className=" w-full flex flex-col  justify-center items-center">
                        <div data-aos="fade-up" data-aos-duration="2000" className='text-gray-300 md:mb-7 mb-2 md:text-2xl text-[16px] px-2  lg:mb-10  flex flex-col lg:space-y-2 space-y-0'>
                            <div>Join our <span className="text-[#f8c629]">Newsletter</span> for exclusive fitness tips, healthy recipes and inspiring stories. </div>
                            <div>Let's build a stronger community together, one lift at a time.</div>
                            <div className=""> Join now for <span className="text-[#f8c629]">FREE</span></div>
                        </div>
                        <iframe onload="iframeLoaded();" loading="lazy" title="1" className="w-[80%] sm:w-[50%] md:w-[40%] lg:w-[30%]" src="https://embeds.beehiiv.com/4d2489e5-a26b-4fab-ae8d-68159c54a655?slim=true" data-test-id="beehiiv-embed" height="52" frameBorder="0" scrolling="no" style={{ margin: 0, borderRadius: '0px', backgroundColor: 'transparent' }} ></iframe>

                    </div>

                </div>
                <div className='text-[#f8c629] animate-bounce flex items-center justify-center absolute md:bottom-5 -bottom-0 lg:h-[45px] lg:w-[45px] h-[30px] w-[30px] md:h-[38px] md:w-[38px] left-1/2  transform -translate-x-1/2 -translate-y-1/2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <polygon points="12 17.414 3.293 8.707 4.707 7.293 12 14.586 19.293 7.293 20.707 8.707 12 17.414" />
                    </svg>
                </div>
            
        </div>
    </div>
);

export default MainPage;