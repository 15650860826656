// import Posts from "./posts";

const PostsPage = () => (
    <div className="bg-[#161616] justify-center text-center flex items-center text-7xl text-white h-screen ">
                <div className="w-[90%] pt-24">
               
                   <iframe loading="lazy" title="3" src='https://widgets.sociablekit.com/rss-feed/iframe/25397183' frameborder='0' width='100%' height='1000'></iframe>
                </div>

    
    </div>
);

export default PostsPage;