const Confirm = () => (
    <div className="bg-[#161616] lg:pt-40 md:pt-16 pt-4 flex-col justify-center text-center flex items-center  text-white ">
     <div className="text-6xl font-serif mt-20 mb-4 text-[#f8c629]">Thanks for Joining us</div>
     <div className="md:text-3xl text-2xl font-serif md:mb-20 mb-10 "> Welcome Email Sent</div>
     <div className="text-gray-300 md:text-2xl text-xl w-[80%] lg:mb-40 md:mb-28 mb-16">
     <div className="mb-2">Just one more step! </div>Please <span className="text-[#f8c629]">confirm </span>your email address by clicking the confirm button in the welcome mail.
     </div>
     <div className="text-gray-300 md:text-2xl text-xl mb-20 w-[80%]">PS- If you don't see Herculift welcome mail in your inbox, check your <span className="text-[#f8c629]">spam</span>, <span className="text-[#f8c629]">promotions</span>, or <span className="text-[#f8c629]">junk folders.</span></div>

    
    </div>
);

export default Confirm;