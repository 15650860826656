const Success = () => (
    <div className="bg-[#161616] h-fit  lg:pt-20 md:pt-16 pt-10 flex-col justify-center text-center flex items-center  text-white ">
        <div className="text-6xl px-2 font-serif mb-4 text-[#f8c629]">Welcome to the family !</div>
        <img src="minions.gif" alt="minions gif" />
        <div className="w-[80%] md:text-2xl text-xl mt-10 text-left text-gray-300">

            <div className="font-bold mb-6 md:text-3xl text-2xl text-white "> Here's what you can expect from us:</div>
            <div className="pl-6 space-y-6 mb-10">

            <div className=""> <span className="text-white font-bold">1 . Regular Updates:</span> Get our newsletter every Wednesday and Saturday, packed with trends, advice, and tips for your fitness journey.</div>
            <div> <span className="text-white font-bold">2 . Tailored Content:</span> Whether you're a gym pro or just starting out, our content is customized to inspire you every step of the way. </div>
            <div> <span className="text-white font-bold">3 . Nutrition Guidance:</span> Fuel your body right with delicious recipes, nutritional advice, and meal planning tips.</div>
            <div> <span className="text-white font-bold">4 . Workout Inspiration:</span> Get inspired with fresh workouts and motivational stories to keep you going strong!</div>
            <div> <span className="text-white font-bold">5 . Community Support:</span> Connect with like-minded individuals, share progress, and find encouragement whenever you need .</div>
            </div>



        </div>
        {/* <div className="text-gray-300 md:text-2xl text-xl mb-20 w-[80%]">PS- If you don't see Herculift welcome mail in your inbox, check your <span className="text-[#f8c629]">spam</span>, <span className="text-[#f8c629]">promotions</span>, or <span className="text-[#f8c629]">junk folders.</span></div> */}


    </div>
);

export default Success;