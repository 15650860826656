
import React, { useEffect } from 'react';
import Aos from "aos";
import 'aos/dist/aos.css'
import MainPage from './components/MainPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PostsPage from './components/PostsPage';
import Success from './components/Success';
import Confirm from './components/Confirm';

const App = () => {

    useEffect(() => { Aos.init() }, [])

    return (

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/signup" element={<MainPage />} />
                <Route path="/posts" element={<PostsPage />} />
                <Route path="/success" element={<Success />} />
                <Route path="/confirm" element={<Confirm />} />

            </Routes>
        </BrowserRouter>
    );
};

export default App;






