
import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Header() {
    const [ham_menu, set_menu] = useState(false);


    return (
        <div className="sticky top-0 z-10 bg-yellow-500  dark:bg-[#161616] dark:text-slate-300 ">
            <div id="nav" className="  flex ">

                <div className=" text-lg md:text-xl font-[620] pl-[7%] pt-4">
                    <a id="link1" href="/">

                        <ul id="animate" className="flex flex-row mb-4">
                            <div> <img alt="herculift logo" className='md:h-[50px] md:w-[50px] h-[45px] w-[45px]' src="herculift.png" /></div>
                            <li className="text-[#f8c629] pt-2 pl-2 ">Herculift</li>
                        </ul>
                    </a>

                </div>
                <div className="flex absolute right-[7%] pt-7">
                    <ul id="full_navbar" className="hidden md:flex justify-end space-x-8 lg:space-x-16">

                        <li id="Signup" className="font-medium md:text-xl text-lg hover:text-[#f8c629] cursor-pointer hover:border-b-4 pb-2 hover:border-yellow-500 ease-in-out duration-[200ms]  "><a id="link" href="/signup">Sign up</a></li>
                        <li id="Posts" className="font-medium  md:text-xl text-lg hover:text-[#f8c629] cursor-pointer hover:border-b-4 pb-2 hover:border-yellow-500 ease-in-out duration-[200ms]"><a id="link" href="/posts">Posts</a></li>

                    </ul>
                    <div className=" cursor-pointer block pb-10 absolute right-[1%] md:hidden" onClick={() => {
                        if (ham_menu === true) {
                            document.getElementById('mobile_menu').className = "absolute right-0 top-[70px] md:hidden  bg-[#161616] h-fit w-full hidden space-y-4";
                            set_menu(false);
                        }
                        else {
                            document.getElementById('mobile_menu').className = "absolute right-0 top-[70px] md:hidden bg-[#161616]    h-fit w-full space-y-4";
                            set_menu(true);
                        }



                    }}>{ham_menu === true ? <CloseIcon className=" scale-110 rotate " /> : <MenuIcon className=" ease-out duration-200 scale-110" />}</div>




                </div>

            </div>
            <div className="-mt-6 bg-red-300">
                <ul id="mobile_menu" className="absolute  right-0 top-23.5 md:hidden items-end bg-red-400 h-fit w-full hidden space-y-4">
                    <li id="Signup2" className="text-white text-end pr-[7%]  font-medium text-lg hover:text-[#f8c629] cursor-pointer  pb-2  ease-in-out duration-[200ms]  "><a id="link" href="/signup">Sign up</a></li>
                    <li id="Posts2" className=" text-white text-end pr-[7%] font-medium text-lg hover:text-[#f8c629] cursor-pointer  pb-2  ease-in-out duration-[200ms]"><a id="link" href="/posts">Posts</a></li>
                </ul>
            </div>
        </div>
    )
};

export default Header;

